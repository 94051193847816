<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลรายการเพิ่มลด</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ประเภทรายการเพิ่มลด</label>
                <Select2 v-model="item.type" :options="types" placeholder="ประเภทรายการเพิ่มลด" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ชื่อรายการเพิ่มลด</label>
                <base-input name="name" placeholder="ชื่อรายการเพิ่มลด" v-model="item.name" :rules="{required: true}"></base-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">รายการย่อย</label>
                <b-form-radio-group v-model="item.sub_cat" :options="options" class="mb-4" value-field="item" text-field="name"></b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="mb-4">
            <label class="form-label optional">รายละเอียด</label>
            <b-form-textarea name="detail" placeholder="รายละเอียด" v-model="item.detail" rows="3" max-rows="5"></b-form-textarea>
          </div>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
export default {
  name: 'product_category-form-product_category',
  data () {
    return {
      types: [],
      options: [
        { item: true, name: 'ใช่' },
        { item: false, name: 'ไม่ใช่' }
      ]
    }
  },
  props: ["item"],
  methods: {
    async getTypes () {
      this.types = await this.HttpServices.getMasterData("/master/getProductTypes");
      if(this.types.length>0){
        this.item.type = this.types[0].id;
      }
    },
  },
  async mounted() {
    await this.getTypes();
  },
}
</script>
